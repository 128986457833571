@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    /* #3D3D3D */
    --gray-dark: 0 0% 24%;
    --green-tag: #4cb592;
    --yellow-tag: #f5c150;
    --blue-tag: #4680ff;
    --action: #2a47ab;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.bg-header-img {
  background-image: url(./Assets/vultron-login.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/*  */
* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Allow editor content to override fonts */
.ProseMirror * {
  font-family: inherit;
}

html body {
  /* overflow: hidden; */
}

body.stop-scroll {
  overflow: hidden;
}

/* #2A47AB */

/* .Toolbar_group__vQrQH {
    max-width: 750px;
} */

.menu-arrow {
  position: absolute;
  width: 9px;
  height: 9px;
  top: -5px;
  right: calc(50% - 9px);
  transform: translateX(-50%) rotate(45deg);
  background: #dbe0e5;
  z-index: -1;
}

.menu-arrow.right {
  right: 46px;
}

/* body *::-webkit-scrollbar,
body *::-webkit-scrollbar-thumb,
body *::-webkit-scrollbar-track, */

.scrollbar-none *::-webkit-scrollbar,
.scrollbar-none *::-webkit-scrollbar-thumb,
.scrollbar-none *::-webkit-scrollbar-track {
  width: 6px;
  height: 6px;
  border-radius: 50px;
  overflow: hidden;
}

/* .mce-content-body::-webkit-scrollbar-track, */

.scrollbar-none *::-webkit-scrollbar-track {
  background-color: #dbe0e548;
}

/* .mce-content-body::-webkit-scrollbar-thumb, */

.scrollbar-none *::-webkit-scrollbar-thumb {
  background-color: #bec3c78a;
}

.scrollbar-0 *::-webkit-scrollbar,
.scrollbar-0 *::-webkit-scrollbar-thumb,
.scrollbar-0 *::-webkit-scrollbar-track {
  width: 0;
  height: 0;
  border-radius: 0;
}

.rmdp-top-class,
.rmdp-wrapper {
  width: 100% !important;
}

.Aidot {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #a782c3;
  margin-right: 4px;
}

.dot {
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #000;
  margin-right: 4px;
}
.draggable {
  cursor: move;
}

.over {
  /* transform: scale(1.04, 1.04); */
  opacity: 0.5;
  /* background-color: #8f8f8f33; */
  /* border-radius: 7px; */
  /* box-shadow: 0 0 22px #00000034; */
}

.dragging {
  transform: scale(1.04, 1.04);
  /* opacity: .5; */
  /* background-color: #8f8f8f33; */
  /* border-radius: 7px; */
  box-shadow: 0 0 22px #00000034;
}
@keyframes jumpingAnimation {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0.3;
  }
  50% {
    transform: translate3d(0, 7px, 0);
    opacity: 0.7;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.typing-container .dot:nth-last-child(1) {
  animation: jumpingAnimation 1.1s 0.1s ease-in infinite;
}

.typing-container .dot:nth-last-child(2) {
  animation: jumpingAnimation 1.1s 0.4s ease-in infinite;
}

.typing-container .dot:nth-last-child(3) {
  animation: jumpingAnimation 1.1s 0.6s ease-in infinite;
}

.typing-container-for-writing-ai .Aidot:nth-last-child(1) {
  animation: jumpingAnimation 1.1s 0.1s ease-in infinite;
}

.typing-container-for-writing-ai .Aidot:nth-last-child(2) {
  animation: jumpingAnimation 1.1s 0.4s ease-in infinite;
}

.typing-container-for-writing-ai .Aidot:nth-last-child(3) {
  animation: jumpingAnimation 1.1s 0.6s ease-in infinite;
}
.fill-content {
  height: fit-content;
}

.mce-content-body {
  height: 100%;
  outline: none;
  border: none;
}
.editable:empty:before {
  content: attr(data-placeholder);
  color: #8f8f8f;
}

.highlighted {
  position: absolute;
  background-color: red;
  opacity: 0.5; /* Adjust the opacity as needed */
  pointer-events: none; /* Allows text selection to work underneath the overlay */
}

.loaderr {
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loaderr::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 6px;
  top: 10px;
  width: 12px;
  height: 12px;
  color: #2a47ab;
  background: currentColor;
  border-radius: 50%;
  box-shadow:
    25px 2px,
    10px 22px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sessions::-webkit-scrollbar,
.sessions::-webkit-scrollbar-thumb,
.sessions::-webkit-scrollbar-track {
  width: 0;
}

.textAnimation {
  height: 30px;
  overflow: hidden;
}

.textAnimation > div > div {
  display: inline-block;
  height: 30px;
  margin-left: 1rem;
}

.textAnimation div:first-child {
  animation: text-animation 6s infinite;
}

@keyframes text-animation {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-60px);
  }

  80% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

.carousel-circles {
  display: flex;
  gap: 0.6rem;
}

.carousel-circle {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  border: 1px solid #2a47ab;
  cursor: pointer;
}

.carousel-circle--active {
  background-color: #2a47ab;
  box-shadow: 0 0 15px rgba(42, 70, 172, 1);
  cursor: auto;
}

.materialBox {
  transform: translateZ(0);
  will-change: width, height;
}

.regenerateIconStyle {
  transition: all 0.5s ease-in-out;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.contract-details-title {
  width: 100%;
}

.contract-details-title span {
  max-width: 95%;
  overflow: hidden;
}

.contract-details-title img {
  opacity: 0;
  visibility: hidden;
}

.contract-details-title:hover img {
  opacity: 1;
  visibility: visible;
}

/* Document prompt modal/input */
.mention_container ::-webkit-scrollbar,
.mention_container ::-webkit-scrollbar-thumb,
.mention_container ::-webkit-scrollbar-track {
  width: 0px;
  height: 0;
  background-color: transparent;
}

.mention_input_wrapper {
  /* box-shadow: 0 0 15px #0000001f; */
}

.mention_input_wrapper * {
  font-size: 0.75rem;
}
.mention_main {
  width: 100%;
}
.mention_main input {
  border: 0;
  outline: 0;
  font-size: 0.75rem !important;
  top: 50% !important;
  transform: translateY(-50%);
  width: 100%;
  background-color: transparent !important;
}
.mention_main input::placeholder {
  color: #5b6b79;
}
.mention_main__highlighter {
  border: 0 !important;
}
.mention_main strong {
  padding: 1px 0;
  border-radius: 5px;
  display: inline-block;
}

/* menu */
.mention_main__suggestions {
  overflow: hidden;
  border-radius: 8px;
}
.mention_wrapper {
  border-radius: 8px;
  width: 100%;
  max-width: 340px;
  max-height: 330px;
  overflow: auto;
  /* box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12); */
}
.mentionItem {
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
  overflow: hidden;
}

.mention_wrapper ul {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: block;
}

.mention_wrapper ul li {
  display: block;
}
.mention_wrapper li:first-child {
  padding-top: 5px;
  border-radius: 8px 8px 0 0;
}
.mention_wrapper li:last-child {
  border-radius: 0 0 8px 8px;
  padding-bottom: 5px;
}
li[aria-selected="true"] > .mentionItem,
.mentionItem:hover,
.mentionItem:focus {
  background-color: #f5f5f5;
}
.mentionItem .icon-wrapper {
  max-width: 19px;
  min-width: 19px;
  padding: 2px;
}
.mentionItem .icon-wrapper img {
  max-width: 100%;
  margin: 0 auto;
}
.mentionItem p {
  max-width: 100%;
  font-size: 15px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tippy-box {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.tippy-content {
  padding: 0;
}
